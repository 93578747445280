export const BINDCOLOR =  "Product Color";

export const BINDSTATE = "State";

export const BINDDISTRICT = "District";

export const BINDTALUK = "Taluk";

export const BRANDTYPE = "Brand Type";

export const DEALERSHIPTYPE = "DealerShip Type";

export const FIRMTYPE = "Firm Type";

export const REGISTERTYPE = "Register Type";

export const PRODUCTDETAILS = "Product Table";

export const PRODUCTFULLDETAILS = "Product Full Details";

export const ADVERTISEMENT = "Advertisement Type";

export const VEHICLE = "Vehicle Type";

export const CHARGER = "Charger Type";

export const SERVICE = "Service Type";

export const INSURANCE = "Insurance Type";

export const INSURANCECOMPANYNAME = "Sale Insurance Company Type";

export const VERSION = "Version Type";

