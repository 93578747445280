import React,{ useState,useEffect,Fragment } from 'react';
import { useHistory } from "react-router-dom";
import useBindState from '../../../common/useBind';
import { webConfig,config } from '../../../../server/Url';
import { BINDSTATE } from '../../../../server/Type';
import axios from 'axios';
import Loader from '../../../loader/Loader';


const StateInsert =()=>{   

    const [formData,setFormData] = useState({
        "action"   : "Insert",
        "type"     : "District",
        "rfId"     : "",
        "stateId"  : "1",
        "dataName" : "",      
        "remarks"  : ""
    });

     const [bindState,bindStateError] = useBindState(BINDSTATE);

    const [active,setActive] = useState(true);
  

    const history = useHistory();
    
    // useEffect(()=>{     
    //     console.log(bindState);
    //     // if (datas.length > 0) {           
    //     //     datas.map(data=>{
    //     //         setFormData({
    //     //             "action"    : "Insert",
    //     //             "type"      : "Product Color",
    //     //             "rfId"      : data.rfId,
    //     //             "dataName"  : data.dataName,      
    //     //             "remarks"   : data.remarks
    //     //         })
    //     //     })
    //     //     setActive(false);
    //     // }

    // },[bindState])

    const onChangeSet =(e)=>setFormData({
        ...formData,
        [e.target.name] : e.target.value
    })

    const submitHandler=(e)=>{
         e.preventDefault();
         setActive(true);   
         axios.post(`${webConfig}bind`,formData,config).then((response)=>{            
            if(response.data.alert == "District Details Added"){
                  history.push("/bindDistrict");             
            }

         }).catch((errMsg)=>{
            console.log(errMsg);
         })  
    }
    return(<Fragment>
        <h1> District Insert </h1>
        <hr />
        <Loader active={ !active } />
           <div className="container">
               <div className="row">
                   <div className="col-md-4"> </div>
                       <div className="col-md-4">
                        <form onSubmit={ submitHandler }   className="was-validated">
                            <div className="form-group" >
                                <label for="uname">State Name :</label>
                                <select className="form-control" value={ formData.stateId } onChange={(e)=>onChangeSet(e)} name="stateId" id="stateId">
                                    {
                                         bindState.length > 0 && bindState.map((data,index)=>{
                                           return <option key={index} value={ data.rfId } > { data.stateName } </option>
                                        })
                                    } 
                                </select>                               
                            </div>                          
                            <div className="form-group">
                                <label for="uname">District Name :</label>
                                <input type="text" className="form-control" value={ formData.dataName } placeholder="Enter District Name"  onChange={(e)=>onChangeSet(e)} name="dataName" id="dataName" />
                            </div>                          
                            <div className="form-group">
                                <label for="uname">Remarks :</label>
                                <textarea  className="form-control" value={ formData.remarks } onChange={(e)=>onChangeSet(e)} name="remarks" id="remarks">  </textarea>
                            </div>           
                            <button type="submit" className="btn btn-info"> Submit </button>
                        </form>
                   </div>
               </div>
           </div>
    </Fragment>)
}
export default StateInsert
