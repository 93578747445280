import React,{ useState,useEffect,Fragment } from 'react';
import { useHistory } from "react-router-dom";
// import useFooter from '../../common/useBind';
import { webConfig,config } from '../../../server/Url';
import axios from 'axios';
import { PRODUCTDETAILS } from '../../../server/Type';
import Loader from '../../loader/Loader';
import useBindState from '../../common/useBind';


const BindFullProductInsert =()=>{   

    const [formData,setFormData] = useState({
        "action"   : "Insert",
        "type"     : "Product Full Details",
        "productTableId" : "",
        "rfId"     : "",
        "dataName" : "",      
        "remarks"  : ""
    });

    // const [datas,errors] = useFooter();

    const [bindProduct,bindProductError] = useBindState(PRODUCTDETAILS,null,"dataName");

    const [active,setActive] = useState(true);  

    const history = useHistory();
    
    // useEffect(()=>{     
    //    // console.log(datas);
    //     if (datas.length > 0) {           
    //         datas.map(data=>{
    //             setFormData({
    //                 "action"    : "Insert",
    //                 "type"      : "Product Color",
    //                 "rfId"      : data.rfId,
    //                 "dataName"  : data.dataName,      
    //                 "remarks"   : data.remarks
    //             })
    //         })
    //         setActive(false);
    //     }

    // },[datas])

    const onChangeSet =(e)=>setFormData({
        ...formData,
        [e.target.name] : e.target.value
    })

    const submitHandler=(e)=>{
         e.preventDefault();
         setActive(true);   
         console.log(formData);
         axios.post(`${webConfig}bind`,formData,config).then((response)=>{
         
            if(response.data.alert == "Product Table Details Added"){
                  history.push("/bindFullProduct");             
            }

         }).catch((errMsg)=>{
            console.log(errMsg);
         })  
    }
    return(<Fragment>
        <h1> Product Category Insert </h1>
        <hr />
        <Loader active={ !active } />
           <div className="container">
               <div className="row">
                   <div className="col-md-4"> </div>
                       <div className="col-md-4">
                        <form onSubmit={ submitHandler }   className="was-validated">
                            <div className="form-group" >
                                <label for="uname">Product Name :</label>
                                <select className="form-control" value={ formData.productTableId } onChange={(e)=>onChangeSet(e)} name="productTableId" id="productTableId">
                                    {
                                         bindProduct.length > 0 && bindProduct.map((data,index)=>{
                                           return <option key={index} value={ data.dataId } > { data.dataName } </option>
                                        })
                                    } 
                                </select>                               
                            </div>   
                            <div className="form-group">
                                <label for="uname">Product Category Name :</label>
                                <input type="text" className="form-control" value={ formData.dataName } placeholder="Enter Product Category Name"  onChange={(e)=>onChangeSet(e)} name="dataName" id="dataName" />
                            </div>                          
                            <div className="form-group">
                                <label for="uname">Remarks :</label>
                                <textarea  className="form-control" value={ formData.remarks } onChange={(e)=>onChangeSet(e)} name="remarks" id="remarks">  </textarea>
                            </div>           
                            <button type="submit" className="btn btn-info"> Submit </button>
                        </form>
                   </div>
               </div>
           </div>
    </Fragment>)
}
export default BindFullProductInsert
