import React, { useState, useEffect, Fragment } from "react";
import { useHistory, useLocation } from "react-router-dom";
import useBindState from "../../../common/useBind";
import { webConfig, config } from "../../../../server/Url";
import { BINDSTATE, BINDDISTRICT, BINDTALUK } from "../../../../server/Type";
import axios from "axios";
import Loader from "../../../loader/Loader";
import useBind from "../../../common/useBind";

const TalukUpdate = () => {
  const history = useHistory();

  const location = useLocation();

  const [formData, setFormData] = useState({
    action: "Insert",
    type: "Taluk",
    rfId: "",
    stateId: "",
    districtId: "",
    dataName: "",
    remarks: "",
  });

  const [bindState, bindStateError] = useBindState(BINDSTATE);

  const [bindDistrict, bindDistrictError] = useBindState(BINDDISTRICT);

  const [talukData, stateError] = useBind(BINDTALUK, location.state.rfId);

  const [active, setActive] = useState(true);

  useEffect(() => {
    if (talukData.length > 0) {
      talukData.map((data) => {
        setFormData({
          action: "Insert",
          type: "Taluk",
          rfId: data.rfId,
          stateId: data.stateId,
          districtId: data.districtId,
          dataName: data.blockName,
          remarks: "",
        });
      });
    }
  }, [talukData]);

  const onChangeSet = (e) =>
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

  const submitHandler = (e) => {
    e.preventDefault();
    setActive(true);
    axios
      .post(`${webConfig}bind`, formData, config)
      .then((response) => {
        // console.log(response);
        if (response.data.alert == "Taluk Details Updated") {
          history.push("/bindTaluk");
        }
      })
      .catch((errMsg) => {
        console.log(errMsg);
      });
  };
  return (
    <Fragment>
      <h1> Taluk Update </h1>
      <hr />
      <Loader active={!active} />
      <div className="container">
        <div className="row">
          <div className="col-md-4"> </div>
          <div className="col-md-4">
            <form onSubmit={submitHandler} className="was-validated">
              <div className="form-group">
                <label for="uname">State Name :</label>
                <select
                  className="form-control"
                  value={formData.stateId}
                  onChange={(e) => onChangeSet(e)}
                  name="stateId"
                  id="stateId"
                >
                  {bindState.length > 0 &&
                    bindState.map((data, index) => {
                      return (
                        <option key={index} value={data.rfId}>
                          {" "}
                          {data.stateName}{" "}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="form-group">
                <label for="uname">District Name :</label>
                <select
                  className="form-control"
                  value={formData.districtId}
                  onChange={(e) => onChangeSet(e)}
                  name="districtId"
                  id="districtId"
                >
                  {bindDistrict.length > 0 &&
                    bindDistrict.map((data, index) => {
                      if (data.stateId == formData.stateId) {
                        return (
                          <option key={index} value={data.rfId}>
                            {" "}
                            {data.districtName}{" "}
                          </option>
                        );
                      }
                    })}
                </select>
              </div>
              <div className="form-group">
                <label for="uname">Taluk Name :</label>
                <input
                  type="text"
                  className="form-control"
                  value={formData.dataName}
                  placeholder="Enter Taluk Name"
                  onChange={(e) => onChangeSet(e)}
                  name="dataName"
                  id="dataName"
                />
              </div>
              <div className="form-group">
                <label for="uname">Remarks :</label>
                <textarea
                  className="form-control"
                  value={formData.remarks}
                  onChange={(e) => onChangeSet(e)}
                  name="remarks"
                  id="remarks"
                >
                  {" "}
                </textarea>
              </div>
              <button type="submit" className="btn btn-info">
                {" "}
                Submit{" "}
              </button>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default TalukUpdate;
