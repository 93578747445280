import React from "react";
import { Link, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { MDBDataTable, Row, Col, Card, CardBody } from 'mdbreact';
import useBind from '../../../common/useBind';
import Loader from '../../../loader/Loader';
import { BINDDISTRICT } from '../../../../server/Type';
import axios  from "axios";
import { webConfig,config } from '../../../../server/Url';

const useStyles = makeStyles({
  table: {
    minWidth: 600,
    border: "2px solid blue",
    boxShadow: "5px 10px #999999",
  },
});

const BindState = () => {
  const history = useHistory();

  var i =0;

  const [districtData, districtError ] = useBind(BINDDISTRICT);
  

  const changeActiveStatus = (status,RfId)=>{

    const  formData={
      "action" : "Delete",
      "type" : "District",
      "rfId" : RfId,
      "status" : status
    }

    axios.post(`${ webConfig }bind`,formData,config).then((response)=>{
      // console.log(response);
      //   setDatas(response.data);  
         window.location.reload(true);
    }).catch((errMsg)=>{      
      // console.log(errMsg);
      //  setErrors(errMsg);      
    })


  }
  // BindDistrictUpdate

   const changeUpdateStatus = ()=>{   
        history.push('/bindDistrictInsert')
    }
    const changeEditOption = (RfId) => {
      history.push("/BindDistrictUpdate", { rfId: RfId });
    };
   const data = districtData !== "null" && {
 
      columns: [
          {
            label:'#',
            field:'id',
          },                  
          {
            label:'State Name',
            field:'StateName',
          },  
          {
            label:'District Name',
            field:'DistrictName',
          },          
          {
              label:'Insert DataTime',
              field:'InsertedDateTime',
          },
          {
              label:'Status',
              field:'IsActive',
          },
          {
              label:'Remarks',
              field:'Remarks',
          },
         {
            label:'Action',
            field:'action',
          },
        ],
        rows:districtData.map((user) => {
            i++;
          return (    
             {
              
               id: i,
  
               StateName:  user.stateName,

               DistrictName : user.districtName,
  
               InsertedDateTime: user.insertedDateTime,
  
               IsActive: user.isActive.data[0] ? 'Active' : 'IsActive',     
               
               Remarks : user.remarks,

              action : [
                <button
                type="submit"
                className="btn btn-primary"
                onClick={() => changeEditOption(user.rfId)}
              >
                Edit
              </button>,
              ],                
           }    
           )    
         }),
      }
    
   return (
   
      <div className="container">
            <Loader active={ districtData.length ? false : true } />
            <div className="row">
              <div className="col-md-9">
             
              </div>
              <div className="col-md-3">
              <button type="button" className="btn btn-primary buttonAlign" onClick={ changeUpdateStatus } >Add District </button>
              </div>
            </div>
          
      <Row className="mb-4">
     

<Col md="12">

          <Card>
          
          <CardBody>
          <Col md="10">
          </Col>
          <Col md="2">
          </Col>
              <MDBDataTable

              striped

              bordered

              hover

              data={data}

              />

          </CardBody>

          </Card>

      </Col>

      </Row>       
     </div>
    )
};

export default React.memo(BindState);
