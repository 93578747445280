import React,{ useState,useEffect,Fragment,useRef } from 'react';
import { useHistory } from "react-router-dom";
// import useFooter from '../../common/useBind';
import { webConfig } from '../../../server/Url';
import axios from 'axios';
import Loader from '../../loader/Loader';


const BindColorInsert =()=>{

    const [formData,setFormData] = useState({
        "action"   : "Insert",
        "type"     : "Brand Type",
        "brandImage" : "",
        "rfId"     : "",
        "dataName" : "",      
        "remarks"  : ""
    });

    const [file, setFile] = useState(''); 

    const handleChange  = (e)=>{

        const file = e.target.files[0]; // accesing file

        setFile(file);      
    }
    

    // const [datas,errors] = useFooter();

    const [active,setActive] = useState(true);

    const el                 = useRef();  

    const history            = useHistory();
    
    // useEffect(()=>{     
    //    // console.log(datas);
    //     if (datas.length > 0) {           
    //         datas.map(data=>{
    //             setFormData({
    //                 "action"    : "Insert",
    //                 "type"      : "Product Color",
    //                 "rfId"      : data.rfId,
    //                 "dataName"  : data.dataName,      
    //                 "remarks"   : data.remarks
    //             })
    //         })
    //         setActive(false);
    //     }

    // },[datas])

    const onChangeSet =(e)=>setFormData({
        ...formData,
        [e.target.name] : e.target.value
    })

    const submitHandler=(e)=>{
         e.preventDefault();
         setActive(true);   

         const body   = new FormData();

         body.append('brandImage',file);
         
         body.append('rfId',"");

         body.append('dataName',formData.dataName);

         body.append('remarks',formData.remarks);
 
         body.append('action','Insert');

         body.append('type','Brand Type');
      
 
         const config = {
             headers: {
                 'Content-Type': 'multipart/form-data'              
             }
         }   

         console.log(`Body Data - ${ JSON.stringify(body) }`)

         axios.post(`${webConfig}bind/Bind_Product`,body,config).then((response)=>{
            //   console.log(response);
            if(response.data.alert == "Brand Type Details Added"){
                  history.push("/bindBrand");             
            }

         }).catch((errMsg)=>{
            console.log(errMsg);
         })  
    }
    return(<Fragment>
        <h1> Brand Insert </h1>
        <hr />
        <Loader active={ !active } />
           <div className="container">
               <div className="row">
                   <div className="col-md-4"> </div>
                       <div className="col-md-4">
                        <form onSubmit={ submitHandler }   className="was-validated">
                            <div className="form-group">
                                <label for="uname">Brand Name :</label>
                                <input type="text" className="form-control" value={ formData.dataName } placeholder="Enter Brand Name"  onChange={(e)=>onChangeSet(e)} name="dataName" id="dataName" />
                            </div>           
                            <div className="form-group">
                                <label for="email">Brand Logo Images </label>
                                <input type="file" className="form-control"   ref={el} onChange={ handleChange } />
                            </div>               
                            <div className="form-group">
                                <label for="uname">Remarks :</label>
                                <textarea  className="form-control" value={ formData.remarks } onChange={(e)=>onChangeSet(e)} name="remarks" id="remarks">  </textarea>
                            </div>           
                            <button type="submit" className="btn btn-info"> Submit </button>
                        </form>
                   </div>
               </div>
           </div>
    </Fragment>)
}
export default BindColorInsert
