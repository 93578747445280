import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { MDBDataTable, Row, Col, Card, CardBody } from 'mdbreact';
import useBind from '../../../common/useBind';
import Loader from '../../../loader/Loader';
import { INSURANCECOMPANYNAME } from '../../../../server/Type';
import '../style.css';
import axios  from "axios";
import { webConfig,config } from '../../../../server/Url';

const useStyles = makeStyles({
  table: {
    minWidth: 600,
    border: "2px solid blue",
    boxShadow: "5px 10px #999999",
  },
});

const BindInsurance = () => {
  const history = useHistory();

  var i =0;

  const [colorData, colorError ] = useBind(INSURANCECOMPANYNAME);
  const [modalIsOpen, setIsOpen] = useState(false);
  

  const changeActiveStatus = (status,RfId)=>{

  //  const  formData={
  //     "action" : "Delete",
  //     "RfId" : RfId,
  //     "IsActive" : status
  //   }

  //   axios.post(`${ webConfig }/webGallery/gallery`,formData,config).then((response)=>{

  //       setDatas(response.data);         

  //   }).catch((errMsg)=>{
  //       setErrors(errMsg);
      
  //   })

  }

  function openModal() {
    setIsOpen(true);
  }

  const changeEditOption = (RfId) => {
    history.push("/bindInsuranceCompanyUpdate", { rfId: RfId });
  };

   const changeUpdateStatus = ()=>{   
        history.push('/bindInsuranceCompanyInsert')
    }
   const data = {
 
      columns: [
          {
            label:'#',
            field:'id',
          },           
          {
            label:'Company Name',
            field:'CompanyName',
          },          
          {
              label:'Insert DataTime',
              field:'InsertedDateTime',
          },
          {
              label:'Status',
              field:'IsActive',
          },
          {
              label:'Remarks',
              field:'Remarks',
          },
         {
            label:'Action',
            field:'action',
          },
        ],
        rows:colorData.map((user) => {
            i++;
          return (    
             {
              
               id: i,
  
               CompanyName:  user.dataName,
  
               InsertedDateTime: user.insertedDateTime,
  
               IsActive: user.isActive.data[0] ? 'Active' : 'IsActive',     
               
               Remarks : user.remarks,

               action: [   
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={() =>
                    changeEditOption(user.rfId)
                  }
                >
                  {" "}
                  Edit{" "}
                </button>, "  ",
                //   <button
                //   type="submit"
                //   className="btn btn-danger"
                //   onClick={openModal}
                //   // onClick={() =>
                //   //   changeActiveStatus(user.isActive.data[0] ? 0 : 1, user.rfId)
                //   // }
                // >
                //  Del
                // </button>,
              ],          
           }    
           )    
         }),
      }
    
   return (
   
      <div className="container">
            <Loader active={ colorData.length ? false : true } />
            <div className="row">
              <div className="col-md-9">             
              </div>
              <div className="col-md-3">
              <button type="button" className="btn btn-primary buttonAlign" onClick={ changeUpdateStatus } >Add Sale Insurance Company </button>
              </div>
            </div>
          
      <Row className="mb-4">
<Col md="12">

          <Card>
          
          <CardBody>
          <Col md="10">
          </Col>
          <Col md="2">
          </Col>
              <MDBDataTable

              striped

              bordered

              hover

              data={data}

              />

          </CardBody>

          </Card>

      </Col>

      </Row>       
     </div>
    )
};

export default React.memo(BindInsurance);
