import React,{ useState,useEffect,Fragment } from 'react';
import { useHistory,useLocation } from "react-router-dom";
// import useFooter from '../../common/useBind';
import { webConfig,config } from '../../../server/Url';
import axios from 'axios';
import { PRODUCTDETAILS } from '../../../server/Type';
import Loader from '../../loader/Loader';
import useBindState from '../../common/useBind';
import useBind from "../../common/useBind";
import { PRODUCTFULLDETAILS } from "../../../server/Type";


const BindFullProductUpdate =()=>{   

    const history = useHistory();

    const location = useLocation();

    const [formData,setFormData] = useState({
        "action"   : "Insert",
        "type"     : "Product Full Details",
        "productTableId" : "",
        "rfId"     : "",
        "dataName" : "",      
        "remarks"  : ""
    });

    const [bindProduct,bindProductError] = useBindState(PRODUCTDETAILS,"","dataName");

    const [active,setActive] = useState(true);  

 
    const [productData, stateError] = useBind(PRODUCTFULLDETAILS, location.state.rfId);     
  
    useEffect(() => {
      if (productData.length > 0) {
        productData.map((data) => {
          setFormData({
            "action"   : "Insert",
            "type"     : "Product Full Details",
            "productTableId" : data.productTableId,
            "rfId"     : data.rfId,
            "dataName" : data.dataName,   
            "remarks"  : data.remarks
          });
        });
      }
    }, [productData]);

    const onChangeSet =(e)=>setFormData({
        ...formData,
        [e.target.name] : e.target.value
    })

    const submitHandler=(e)=>{
         e.preventDefault();
         setActive(true);   
         axios.post(`${webConfig}bind`,formData,config).then((response)=>{
            //  console.log(response);
            if(response.data.alert == "Product Table Details Updated"){
                  history.push("/bindFullProduct");             
            }

         }).catch((errMsg)=>{
            console.log(errMsg);
         })  
    }
    return(<Fragment>
        <h1> Product Category Insert </h1>
        <hr />
        <Loader active={ !active } />
           <div className="container">
               <div className="row">
                   <div className="col-md-4"> </div>
                       <div className="col-md-4">
                        <form onSubmit={ submitHandler }   className="was-validated">
                            <div className="form-group" >
                                <label for="uname">Product Name :</label>
                                <select className="form-control" value={ formData.productTableId } onChange={(e)=>onChangeSet(e)} name="productTableId" id="productTableId">
                                    {
                                         bindProduct.length > 0 && bindProduct.map((data,index)=>{
                                           return <option key={index} value={ data.dataId } > { data.dataName } </option>
                                        })
                                    } 
                                </select>                               
                            </div>   
                            <div className="form-group">
                                <label for="uname">Product Category Name :</label>
                                <input type="text" className="form-control" value={ formData.dataName } placeholder="Enter Product Category Name"  onChange={(e)=>onChangeSet(e)} name="dataName" id="dataName" />
                            </div>                          
                            <div className="form-group">
                                <label for="uname">Remarks :</label>
                                <textarea  className="form-control" value={ formData.remarks } onChange={(e)=>onChangeSet(e)} name="remarks" id="remarks">  </textarea>
                            </div>           
                            <button type="submit" className="btn btn-info"> Submit </button>
                        </form>
                   </div>
               </div>
           </div>
    </Fragment>)
}
export default BindFullProductUpdate
