import React,{ useState,useEffect,Fragment } from 'react';
import { useHistory,useLocation } from "react-router-dom";
import { webConfig,config } from '../../../server/Url';
import axios from 'axios';
import Loader from '../../loader/Loader';
import useBind from "../../common/useBind";
import { BINDCOLOR } from "../../../server/Type";

const BindColorUpdate =()=>{

    const history = useHistory();

    const location = useLocation();

    const [formData,setFormData] = useState({
        "action"   : "Insert",
        "type"     : "Product Color",
        "rfId"     : "",
        "dataName" : "",      
        "hexa"      : "",
        "remarks"  : ""
    });

    const [active,setActive] = useState(true);
    
    const [colorData, stateError] = useBind(BINDCOLOR, location.state.rfId);

    //  console.log(colorData)
  
    useEffect(() => {
      if (colorData.length > 0) {
        colorData.map((data) => {
          setFormData({
            "action"   : "Insert",
            "type"     : "Product Color",
            "rfId"     : data.rfId,
            "dataName" :  data.dataName,      
            "hexa"      : data.hexa,
            "remarks"  : data.remarks
          });
        });
      }
    }, [colorData]);

    const onChangeSet =(e)=>setFormData({
        ...formData,
        [e.target.name] : e.target.value
    })

    const submitHandler=(e)=>{
         e.preventDefault();
         setActive(true);   
         axios.post(`${webConfig}bind`,formData,config).then((response)=>{
             console.log(response);
            if(response.data.alert == "Product Color Details Updated"){
                  history.push("/bindColor");             
            }

         }).catch((errMsg)=>{
            console.log(errMsg);
         })  
    }
    return(<Fragment>
        <h1> Color Update </h1>
        <hr />
        <Loader active={ !active } />
           <div className="container">
               <div className="row">
                   <div className="col-md-4"> </div>
                       <div className="col-md-4">
                        <form onSubmit={ submitHandler }   className="was-validated">
                            <div className="form-group">
                                <label for="uname">Color Name :</label>
                                <input type="text" className="form-control" value={ formData.dataName } placeholder="Enter Color Name"  onChange={(e)=>onChangeSet(e)} name="dataName" id="dataName" />
                            </div>                          
                            <div className="form-group">
                                <label for="uname">Hexa Code :</label>
                                <input type="text" className="form-control" value={ formData.hexa } placeholder="Enter Hexa Code"  onChange={(e)=>onChangeSet(e)} name="hexa" id="hexa" />
                            </div>                          
                            <div className="form-group">
                                <label for="uname">Remarks :</label>
                                <textarea  className="form-control" value={ formData.remarks } onChange={(e)=>onChangeSet(e)} name="remarks" id="remarks">  </textarea>
                            </div>           
                            <button type="submit" className="btn btn-info"> Submit </button>
                        </form>
                   </div>
               </div>
           </div>
    </Fragment>)
}
export default BindColorUpdate
