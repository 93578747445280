import { useState, useEffect } from "react";
import { webConfig, config } from "../../server/Url";
import axios from "axios";

const useContact = (type, rfId, orderName) => {
  // console.log(rfId)
  const [datas, setDatas] = useState([]);

  const [errors, setErrors] = useState([]);

  var formData = "";

  if (orderName) {
    formData = {
      action: "View",
      limit: "",
      order: "Asc",
      webView: "yes",
      type: type,
      orderName: orderName,
      rfId:  rfId,   
    };
  } else {
    formData = {
      action: "View",
      limit: "",
      order: "Asc",
      webView: "yes",
      type: type,
      orderName: "",
      rfId:  rfId,  
    };
  }  
  
  const galleryGet = () => {
    axios
      .post(`${webConfig}bind`, formData, config)
      .then((response) => {
        setDatas(response.data.data);       
      })
      .catch((errMsg) => {
        setErrors(errMsg);
      });
  };

  useEffect(() => {   
    galleryGet();  
  }, []);

  return [datas, errors];
};
export default useContact;
