import React,{ useState,useEffect,Fragment,useRef } from 'react';
import { useHistory,useLocation } from "react-router-dom";
import { webConfig } from '../../../server/Url';
import axios from 'axios';
import Loader from '../../loader/Loader';
import useBind from "../../common/useBind";
import { BRANDTYPE } from "../../../server/Type";

const BindBrandUpdate =()=>{

    const location = useLocation();

    const [formData,setFormData] = useState({
        "action"   : "Insert",
        "type"     : "Brand Type",
        "brandImage" : "",
        "rfId"     : "",
        "dataName" : "",      
        "remarks"  : ""
    });

    const [file, setFile] = useState(''); 

    const handleChange  = (e)=>{

        const file = e.target.files[0]; // accesing file

        setFile(file);      
    }
    

    // const [datas,errors] = useFooter();

    const [active,setActive] = useState(true);

    const el                 = useRef();  

    const history            = useHistory();
    
    const [brandData, stateError] = useBind(BRANDTYPE, location.state.rfId);

      console.log(brandData)
  
    useEffect(() => {
      if (brandData.length > 0) {
        brandData.map((data) => {
          setFormData({
            "action"   : "Insert",
            "type"     : "Brand Type",
            "brandImage" : data.brandImage,
            "rfId"     : data.rfId,
            "dataName" : data.dataName,  
            "remarks"  : data.remarks
          });
        });
      }
    }, [brandData]);

    const onChangeSet =(e)=>setFormData({
        ...formData,
        [e.target.name] : e.target.value
    })

    const submitHandler=(e)=>{
         e.preventDefault();
         setActive(true);   

         const body   = new FormData();

         body.append('brandImage',file);
         
         body.append('rfId',formData.rfId);

         body.append('dataName',formData.dataName);

         body.append('remarks',formData.remarks);
 
         body.append('action','Insert');

         body.append('type','Brand Type');
      
 
         const config = {
             headers: {
                 'Content-Type': 'multipart/form-data'              
             }
         }   

         console.log(`Body Data - ${ JSON.stringify(body) }`)

         axios.post(`${webConfig}bind/Bind_Product`,body,config).then((response)=>{
               console.log(response);
            if(response.data.alert == "Brand Type Details Updated"){
                  history.push("/bindBrand");             
            }

         }).catch((errMsg)=>{
            console.log(errMsg);
         })  
    }
    return(<Fragment>
        <h1> Brand Insert </h1>
        <hr />
        <Loader active={ !active } />
           <div className="container">
               <div className="row">
                   <div className="col-md-4"> </div>
                       <div className="col-md-4">
                        <form onSubmit={ submitHandler }   className="was-validated">
                        <input type="hidden" className="form-control" value={ formData.rfId }  onChange={(e)=>onChangeSet(e)} name="rfId" id="rfId" />
                            <div className="form-group">
                                <label for="uname">Brand Name :</label>
                                <input type="text" className="form-control" value={ formData.dataName } placeholder="Enter Brand Name"  onChange={(e)=>onChangeSet(e)} name="dataName" id="dataName" />
                            </div>           
                            <div className="form-group">
                                <label for="email">Brand Logo Images <img src={ formData.brandImage }  height="75" width="75" /> </label>
                                <input type="file" className="form-control"   ref={el} onChange={ handleChange } />
                            </div>               
                            <div className="form-group">
                                <label for="uname">Remarks :</label>
                                <textarea  className="form-control" value={ formData.remarks } onChange={(e)=>onChangeSet(e)} name="remarks" id="remarks">  </textarea>
                            </div>           
                            <button type="submit" className="btn btn-info"> Submit </button>
                        </form>
                   </div>
               </div>
           </div>
    </Fragment>)
}
export default BindBrandUpdate
