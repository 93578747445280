import React, { useState, Fragment, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { webConfig, config } from "../../../../server/Url";
import axios from "axios";
import Loader from "../../../loader/Loader";
import { BINDSTATE } from "../../../../server/Type";
import useBind from "../../../common/useBind";

const StateUpdate = (props) => {
  const history = useHistory();

  const location = useLocation();

  const [formData, setFormData] = useState({
    action: "Insert",
    type: "State",
    rfId: "",
    stateCode: "",
    dataName: "",
    remarks: "",
  });
  const [active, setActive] = useState(true);

  const [stateData, stateError] = useBind(BINDSTATE, location.state.rfId);
  
  useEffect(() => {
    if (stateData.length > 0) {
      stateData.map((data) => {
        setFormData({
          action: "Insert",
          type: "State",
          rfId: data.rfId,
          stateCode: data.stateCode,
          dataName: data.stateName,
          remarks: data.remarks,
        });
      });
    }
  }, [stateData]);

  const onChangeSet = (e) =>
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

  const submitHandler = (e) => {
    e.preventDefault();
    setActive(true);
    axios
      .post(`${webConfig}bind`, formData, config)
      .then((response) => {
        if (response.data.alert == "State Details  Updated") {
          history.push("/bindState");
        }
      })
      .catch((errMsg) => {
        // console.log(errMsg);
      });
  };
  return (
    <Fragment>
      <h1> State Update </h1>
      <hr />
      <Loader active={stateData.length ? false : true} />
      <div className="container">
        <div className="row">
          <div className="col-md-4"> </div>
          <div className="col-md-4">
            <form onSubmit={submitHandler} className="was-validated">
              <div className="form-group">
                <label for="uname">State Code :</label>
                <input
                  type="text"
                  className="form-control"
                  value={formData.stateCode}
                  placeholder="Enter State Code"
                  onChange={(e) => onChangeSet(e)}
                  name="stateCode"
                  id="stateCode"
                />
              </div>
              <div className="form-group">
                <label for="uname">State Name :</label>
                <input
                  type="text"
                  className="form-control"
                  value={formData.dataName}
                  placeholder="Enter State Name"
                  onChange={(e) => onChangeSet(e)}
                  name="dataName"
                  id="dataName"
                />
              </div>
              <div className="form-group">
                <label for="uname">Remarks :</label>
                <textarea
                  className="form-control"
                  value={formData.remarks}
                  onChange={(e) => onChangeSet(e)}
                  name="remarks"
                  id="remarks"
                >
                  {" "}
                </textarea>
              </div>
              <button type="submit" className="btn btn-info">
                {" "}
                Update{" "}
              </button>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default StateUpdate;
