import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { MDBDataTable, Row, Col, Card, CardBody } from "mdbreact";
import useBind from "../../common/useBind";
import Loader from "../../loader/Loader";
import { BINDCOLOR } from "../../../server/Type";
import "./style.css";
import axios from "axios";
import { webConfig, config } from "../../../server/Url";
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};


const useStyles = makeStyles({
  table: {
    minWidth: 600,
    border: "2px solid blue",
    boxShadow: "5px 10px #999999",
  },
});

const BindColor = () => {
  const history = useHistory();

  var i = 0;

  const [colorData, colorError] = useBind(BINDCOLOR);

  const [datas, setDatas] = useState();

  const [errors, setErrors] = useState();

  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }


  const changeActiveStatus = (status, RfId) => {
    const formData = {
      action: "Delete",
      type: "Product Color",
      rfId: RfId,
      status: status,
    };

    axios
      .post(`${webConfig}bind`, formData, config)
      .then((response) => {
        // console.log(response);
        //   setDatas(response.data);
        window.location.reload(true);
      })
      .catch((errMsg) => {
        // console.log(errMsg);
        setErrors(errMsg);
      });
  };

  const changeUpdateStatus = () => {
    history.push("/bindColorInsert");
  };

  const changeEditOption = (RfId) => {
    history.push("/bindColorUpdate", { rfId: RfId });
  };
  const data = colorData !== "null" && {
    columns: [
      {
        label: "#",
        field: "id",
      },
      {
        label: "Color Name",
        field: "ColorName",
      },
      {
        label: "Hexa Code",
        field: "HexaCode",
      },
      {
        label: "Insert DataTime",
        field: "InsertedDateTime",
      },
      {
        label: "Status",
        field: "IsActive",
      },
      {
        label: "Remarks",
        field: "Remarks",
      },
      {
        label: "Action",
        field: "action",
      },
    ],
    rows: colorData?.map((user) => {
      i++;
      return {
        id: i,

        ColorName: user.dataName,

        HexaCode: user.hexa,

        InsertedDateTime: user.insertedDateTime,

        IsActive: user.isActive.data[0] ? "Active" : "IsActive",

        Remarks: user.remarks,

        action: [   
          <button
            type="submit"
            className="btn btn-primary"
            onClick={() =>
              changeEditOption(user.rfId)
            }
          >
            {" "}
            Edit{" "}
          </button>, "  ",
            <button
            type="submit"
            className="btn btn-danger"
            onClick={openModal}
            // onClick={() =>
            //   changeActiveStatus(user.isActive.data[0] ? 0 : 1, user.rfId)
            // }
          >
           Del
          </button>,
        ],
      };
    }),
  };

  return (
    <div className="container">
      <Loader active={colorData.length ? false : true} />
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h2>Are You Sure to Delete ? </h2>    
        <div className="modalButton" >  
        <button   type="submit"
            className="btn btn-info" onClick={closeModal}>Yes</button>
            <br />
        <button   type="submit"
            className="btn btn-danger modalButtonMargin"  onClick={closeModal}>close</button>
        </div>
      </Modal>
      <div className="row">
        <div className="col-md-9"></div>
        <div className="col-md-3">
          <button
            type="button"
            className="btn btn-primary buttonAlign"
            onClick={changeUpdateStatus}
          >
            Add Color{" "}
          </button>
        </div>
      </div>

      <Row className="mb-4">
        <Col md="12">
          <Card>
            <CardBody>
              <Col md="10"></Col>
              <Col md="2"></Col>
              <MDBDataTable
                striped
                bordered
                hover
                data={colorData !== "null" && data}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default React.memo(BindColor);
