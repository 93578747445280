import React, { Fragment } from 'react';

import LoadingOverlay from 'react-loading-overlay'

import HashLoader from 'react-spinners/HashLoader'

const Loader = ({ active })=>{

    return(<Fragment>
         <LoadingOverlay  active={active}  styles={{ spinner: (base) => ({
        ...base,
        width: '200px',
        '& svg circle': {
            stroke: 'rgb(23,162,184)'
        }
        })
    }}
spinner={<HashLoader />} ></LoadingOverlay>  
    </Fragment>)

}
export default Loader
