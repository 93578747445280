import React from "react";
import { Link, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { MDBDataTable, Row, Col, Card, CardBody } from 'mdbreact';
import useBind from '../../common/useBind';
import Loader from '../../loader/Loader';
import { PRODUCTDETAILS } from '../../../server/Type';

const useStyles = makeStyles({
  table: {
    minWidth: 600,
    border: "2px solid blue",
    boxShadow: "5px 10px #999999",
  },
});

const BindProduct = () => {
  const history = useHistory();

  var i =0;

  const [productData, colorError ] = useBind(PRODUCTDETAILS);
  

  const changeActiveStatus = (status,RfId)=>{

  //  const  formData={
  //     "action" : "Delete",
  //     "RfId" : RfId,
  //     "IsActive" : status
  //   }

  //   axios.post(`${ webConfig }/webGallery/gallery`,formData,config).then((response)=>{

  //       setDatas(response.data);         

  //   }).catch((errMsg)=>{
  //       setErrors(errMsg);
      
  //   })

  }  
   const data = {
 
      columns: [
          {
            label:'#',
            field:'id',
          }, 
          {
            label:'Product Name',
            field:'ProductName',
          },          
          {
              label:'Insert DataTime',
              field:'InsertedDateTime',
          },
          {
              label:'Status',
              field:'IsActive',
          },
          {
              label:'Remarks',
              field:'Remarks',
          },
         {
            label:'Action',
            field:'action',
          },
        ],
        rows:productData.map((user) => {
            i++;
          return (    
             {
              
               id: i,
  
               ProductName:  user.dataName,
  
               InsertedDateTime: user.insertedDateTime,
  
               IsActive: user.isActive.data[0] ? 'Active' : 'IsActive',     
               
               Remarks : user.remarks,                           
           }    
           )    
         }),
      }
    
   return (
   
      <div className="container">
            <Loader active={ productData.length ? false : true } />           
          
      <Row className="mb-4">
     

<Col md="12">

          <Card>
          
          <CardBody>
          <Col md="10">
          </Col>
          <Col md="2">
          </Col>
              <MDBDataTable

              striped

              bordered

              hover

              data={data}

              />

          </CardBody>

          </Card>

      </Col>

      </Row>       
     </div>
    )
};

export default React.memo(BindProduct);
