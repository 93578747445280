import React, { useState } from "react";
import axios from 'axios';
import "./style.css";
import CustomInput from "../../component/style/CustomInput";
import { Link,useHistory } from "react-router-dom";
import Button from "../../component/style/Button";
import { webConfig,config } from '../../server/Url';

const Login =()=> {

  const history = useHistory();

 const  [state,setState] = useState({
  mobileNo: "",
  userPassword: "",
    action: "Login",
    registerType : "1",
    type : "Web"
  });

  const login = "Login" ;

 const handleChange = e => {
    setState({ ...state,[e.currentTarget.id]: e.currentTarget.value });
  };

  const submitHandler = ()=>{

    console.log(state)

      axios.post(`${webConfig}login`,state,config).then((response)=>{

        console.log(response);

        if(response.data.message === "Success")
        {
          localStorage.setItem(login, response.data.data);
          history.push("/");
          window.location.reload(true);
        }       

      }).catch((error)=>{

        console.log(error);

      });
  }


    return (      
        <form  className="form"  >
          <CustomInput
            labelText="Mobile No"
            id="mobileNo"
            formControlProps={{
              fullWidth: true
            }}
             value={ state.mobileNo }
             handleChange={(e)=>handleChange(e)}            
            type="text"
          />
          <CustomInput
            labelText="Password"
            id="userPassword"
            formControlProps={{
              fullWidth: true
            }}
            value={ state.userPassword }
            handleChange={(e)=>handleChange(e)}          
            type="password"
          />

          <Button type="button" color="primary" className="form__custom-button" onClick={ ()=>submitHandler() }  >
            Log in
          </Button>
        </form>   
    );
}

export default Login
