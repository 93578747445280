import React from "react";
import { Link, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { MDBDataTable, Row, Col, Card, CardBody } from "mdbreact";
import useBind from "../../common/useBind";
import Loader from "../../loader/Loader";
import { BRANDTYPE } from "../../../server/Type";
import "./style.css";
import axios from "axios";
import { webConfig, config } from "../../../server/Url";

const useStyles = makeStyles({
  table: {
    minWidth: 600,
    border: "2px solid blue",
    boxShadow: "5px 10px #999999",
  },
});

const BindBrand = () => {
  const history = useHistory();

  var i = 0;

  const [brandData, brandError] = useBind(BRANDTYPE);

  console.log(brandData);

  const changeActiveStatus = (status, RfId) => {
    const formData = {
      action: "Delete",
      type: "Brand Type",
      rfId: RfId,
      status: status,
    };

    axios
      .post(`${webConfig}bind`, formData, config)
      .then((response) => {
        // console.log(response);
        //   setDatas(response.data);
        window.location.reload(true);
      })
      .catch((errMsg) => {
        // console.log(errMsg);
        //  setErrors(errMsg);
      });
  };

  //bindBrandUpdate
  const changeUpdateStatus = () => {
    history.push("/bindBrandInsert");
  };

  const changeEditOption = (RfId) => {
    history.push("/bindBrandUpdate", { rfId: RfId });
  };
  const data = brandData !== "null" && {
    columns: [
      {
        label: "#",
        field: "id",
      },
      {
        label: "Brand Name",
        field: "BrandName",
      },
      {
        label: "Brand Logo",
        field: "BrandLogo",
      },
      {
        label: "Insert DataTime",
        field: "InsertedDateTime",
      },
      {
        label: "Status",
        field: "IsActive",
      },
      {
        label: "Remarks",
        field: "Remarks",
      },
      {
        label: "Action",
        field: "action",
      },
    ],
    rows: brandData.map((user) => {
      i++;
      return {
        id: i,

        BrandName: user.dataName,

        BrandLogo: (
          <img
            src={user.brandImage}
            decode={false}
            alt="IMG"
            width="100"
            height="100"
          />
        ),

        InsertedDateTime: user.insertedDateTime,

        IsActive: user.isActive.data[0] ? "Active" : "IsActive",

        Remarks: user.remarks,

        action: [
          //
          <button
            type="submit"
            className="btn btn-primary"
            onClick={() => changeEditOption(user.rfId)}
          >
            {" "}
            Edit{" "}
          </button>,
          " ",
          <button
            type="submit"
            className="btn btn-danger"
            onClick={() =>
              changeActiveStatus(user.isActive.data[0] ? 0 : 1, user.rfId)
            }
          >
             Del {" "}
          </button>,
          "  ",
        ],
      };
    }),
  };

  return (
    <div className="container">
      <Loader active={brandData.length ? false : true} />
      <div className="row">
        <div className="col-md-9"></div>
        <div className="col-md-3">
          <button
            type="button"
            className="btn btn-primary buttonAlign"
            onClick={changeUpdateStatus}
          >
            Add Brand{" "}
          </button>
        </div>
      </div>

      <Row className="mb-4">
        <Col md="12">
          <Card>
            <CardBody>
              <Col md="10"></Col>
              <Col md="2"></Col>
              <MDBDataTable striped bordered hover data={data} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default React.memo(BindBrand);
