import Login from './pages/login/Login';
import Index from '../src/component/example/Index';
import { BrowserRouter as Router,Route,Switch } from 'react-router-dom';
import DashBoard from './pages/dashboard/LoginHeader';
import BindColor from './pages/bind/color/BindColor';
import BindColorInsert from './pages/bind/color/BindColorInsert';
import BindColorUpdate from './pages/bind/color/BindColorUpdate';
import BindState from './pages/bind/place/state/BindState';
import StateInsert from './pages/bind/place/state/StateInsert';
import StateUpdate from './pages/bind/place/state/StateUpdate';
import BindDistrict from './pages/bind/place/district/BindDistrict';
import BindDistrictUpdate from './pages/bind/place/district/DistrictUpdate';
import DistrictInsert from './pages/bind/place/district/DistrictInsert';
import BindTaluk from './pages/bind/place/taluk/BindTaluk';
import TalukInsert from './pages/bind/place/taluk/TalukInsert';
import TalukUpdate from './pages/bind/place/taluk/TalukUpdate';
import BindBrand from './pages/bind/brand/BindBrand';
import BindBrandInsert from './pages/bind/brand/BindBrandInsert';
import BindBrandUpdate from './pages/bind/brand/BindBrandUpdate';
import BindDealerShip from './pages/bind/dealership/BindDealerShip';
import BindDealerShipInsert from './pages/bind/dealership/BindDealerShipInsert';
import BindDealerShipUpdate from './pages/bind/dealership/BindDealerShipUpdate';
import BindFirm from './pages/bind/firm/BindFirm';
import BindFirmInsert from './pages/bind/firm/BindFirmInsert';
import BindFirmUpdate from './pages/bind/firm/BindFirmUpdate';
import BindRegister from './pages/bind/register/BindRegister';
import BindRegisterInsert from './pages/bind/register/BindRegisterInsert';
import BindRegisterUpdate from './pages/bind/register/BindRegisterUpdate';
import BindProduct from './pages/bind/product/BindProduct';
import BindFullProduct from './pages/bind/product/BindFullProduct';
import BindFullProductInsert from './pages/bind/product/BindFullProductInsert';
import BindFullProductUpdate from './pages/bind/product/BindFullProductUpdate';

import BindAdvertisement from './pages/bind/advertisement/BindAdvertisement';
import BindAdvertisementInsert from './pages/bind/advertisement/BindAdvertisementInsert';
import BindVehicle from './pages/bind/vehicle/BindVehicle';
import BindVehicleInsert from './pages/bind/vehicle/BindVehicleInsert';
import BindCharger from './pages/bind/charger/BindCharger';
import BindChargerInsert from './pages/bind/charger/BindChargerInsert';
import BindService from './pages/bind/service/BindService';
import BindServiceInsert from './pages/bind/service/BindServiceInsert';
import BindInsurance from './pages/bind/Insurance/BindInsurance';
import BindInsuranceInsert from './pages/bind/Insurance/BindInsuranceInsert';
import BindCompany from './pages/bind/Insurance/CompanyName/BindCompany';
import BindInsuranceCompanyInsert from './pages/bind/Insurance/CompanyName/CompanyInsert'
import BindInsuranceCompanyUpdate from './pages/bind/Insurance/CompanyName/CompanyUpdate';

import BindVersion from './pages/bind/version/BindVersion';
import BindVersionInsert from './pages/bind/version/BindVersionInsert';
 
function App() {
  // console.log(localStorage.getItem("Login"));
  return (
    <>
    <Router>
           { (localStorage.getItem("Login")) &&   <DashBoard /> }
           { (localStorage.getItem("Login")  === null) && <Route path="/" exact="true" component={Login} />  }
      
            <Switch>         
                { (localStorage.getItem("Login")) && <Route path="/bindColor" component={ BindColor} /> }
                { (localStorage.getItem("Login")) && <Route path="/bindColorInsert" component = {BindColorInsert} /> }
                { (localStorage.getItem("Login")) && <Route path="/bindColorUpdate" component = {BindColorUpdate} /> }
                { (localStorage.getItem("Login")) && <Route path="/bindState" component={ BindState } /> }
                { (localStorage.getItem("Login")) && <Route path="/bindStateInsert" component={ StateInsert } /> }
                { (localStorage.getItem("Login")) && <Route path="/bindStateUpdate" component={ StateUpdate } /> }
                { (localStorage.getItem("Login")) && <Route path="/bindDistrict" component={ BindDistrict } /> }
                { (localStorage.getItem("Login")) && <Route path="/BindDistrictUpdate" component={ BindDistrictUpdate } /> }
                { (localStorage.getItem("Login")) && <Route path="/bindDistrictInsert" component={ DistrictInsert } /> }
                { (localStorage.getItem("Login")) && <Route path="/bindTaluk" component={ BindTaluk } /> }
                { (localStorage.getItem("Login")) && <Route path="/bindTalukInsert" component={ TalukInsert } /> }
                { (localStorage.getItem("Login")) && <Route path="/bindTalukUpdate" component={ TalukUpdate } /> }
                { (localStorage.getItem("Login")) && <Route path="/bindBrand" component={ BindBrand } /> }
                { (localStorage.getItem("Login")) && <Route path="/bindBrandInsert" component={ BindBrandInsert } /> }
                { (localStorage.getItem("Login")) && <Route path="/bindBrandUpdate" component={ BindBrandUpdate } /> }
                { (localStorage.getItem("Login")) && <Route path="/bindDealerShip" component={ BindDealerShip } /> }
                { (localStorage.getItem("Login")) && <Route path="/bindDealerShipInsert" component={ BindDealerShipInsert } /> }
                { (localStorage.getItem("Login")) && <Route path="/bindDealerShipUpdate" component={ BindDealerShipUpdate } /> }
                { (localStorage.getItem("Login")) && <Route path="/bindFirm" component={ BindFirm } /> }
                { (localStorage.getItem("Login")) && <Route path="/bindFirmInsert" component={ BindFirmInsert } /> }
                { (localStorage.getItem("Login")) && <Route path="/bindFirmUpdate" component={ BindFirmUpdate } /> }
                { (localStorage.getItem("Login")) && <Route path="/bindRegister" component={ BindRegister } /> }
                { (localStorage.getItem("Login")) && <Route path="/bindRegisterInsert" component={ BindRegisterInsert } /> }
                { (localStorage.getItem("Login")) && <Route path="/bindRegisterUpdate" component={ BindRegisterUpdate } /> }
                { (localStorage.getItem("Login")) && <Route path="/bindProduct" component={ BindProduct } /> }
                { (localStorage.getItem("Login")) && <Route path="/bindFullProduct" component={ BindFullProduct } /> }
                { (localStorage.getItem("Login")) && <Route path="/bindFullProductInsert" component={ BindFullProductInsert } /> }
                { (localStorage.getItem("Login")) && <Route path="/bindFullProductUpdate" component={ BindFullProductUpdate } /> }

                { (localStorage.getItem("Login")) && <Route path="/bindAdvertisement" component={ BindAdvertisement } /> }
                { (localStorage.getItem("Login")) && <Route path="/bindAdvertisementInsert" component={ BindAdvertisementInsert } /> }
                { (localStorage.getItem("Login")) && <Route path="/bindVehicle" component={ BindVehicle } /> }
                { (localStorage.getItem("Login")) && <Route path="/bindVehicleInsert" component={ BindVehicleInsert } /> }
                { (localStorage.getItem("Login")) && <Route path="/bindCharger" component={ BindCharger } /> }
                { (localStorage.getItem("Login")) && <Route path="/bindChargerInsert" component={ BindChargerInsert } /> }
                { (localStorage.getItem("Login")) && <Route path="/bindService" component={ BindService } /> }
                { (localStorage.getItem("Login")) && <Route path="/bindServiceInsert" component={ BindServiceInsert } /> }
                { (localStorage.getItem("Login")) && <Route path="/bindInsurance" component={ BindInsurance } /> }
                { (localStorage.getItem("Login")) && <Route path="/bindInsuranceInsert" component={ BindInsuranceInsert } /> }
                { (localStorage.getItem("Login")) && <Route path="/bindVersion" component={ BindVersion } /> }
                { (localStorage.getItem("Login")) && <Route path="/bindVersionInsert" component={ BindVersionInsert } /> }                
                { (localStorage.getItem("Login")) && <Route path="/bindInsuranceCompany" component={ BindCompany } /> }                
                { (localStorage.getItem("Login")) && <Route path="/bindInsuranceCompanyInsert" component={ BindInsuranceCompanyInsert } /> }                
                { (localStorage.getItem("Login")) && <Route path="/bindInsuranceCompanyUpdate" component={ BindInsuranceCompanyUpdate } /> }                
            </Switch>     
    </Router>  
    </>
  );
}
export default App;
