import React from 'react';

import { Link } from 'react-router-dom';

const LoginHeader = ()=>{

        return(<div>
            <div className="container-fluid"  >
                    <div className="row">
                        <div className="col-md-12  col-md-12 col-lg-12 col-xl-12">
                            <nav className="navbar navbar-expand-sm bg-info navbar-dark">
                                <Link className="navbar-brand" to="/">Go By Electric</Link>
                                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                                        <span className="navbar-toggler-icon"></span>
                                    </button>
                                    <div className="collapse navbar-collapse" id="collapsibleNavbar">
                                        <ul className="navbar-nav">
                                            <li className="nav-item navbar-center-align">
                                                <Link className="nav-link navbar-hyber-link-color" to="/" >HOME</Link>
                                            </li>                                      
                                            <li className="dropdown nav-item navbar-center-align">
                                                <a className="dropdown-toggle nav-link navbar-hyber-link-color" data-toggle="dropdown" href="#">Common
                                                <span className="caret"></span></a>
                                                <ul  className="dropdown-menu">
                                                <li  className="dropdown-item" ><Link to="/bindColor">Bind Color</Link></li>
                                                <li  className="dropdown-item" ><Link to="/bindBrand">Bind Brand</Link></li>
                                                <li  className="dropdown-item" ><Link to="/bindDealerShip">Bind DealerShip</Link></li>
                                                <li  className="dropdown-item" ><Link to="/bindFirm">Bind Firm</Link></li>
                                                <li  className="dropdown-item" ><Link to="/bindRegister">Bind Regiter</Link></li>
                                               
                                                </ul>
                                            </li> 
                                            <li className="dropdown nav-item navbar-center-align">
                                                <a className="dropdown-toggle nav-link navbar-hyber-link-color" data-toggle="dropdown" href="#">Place
                                                <span className="caret"></span></a>
                                                <ul  className="dropdown-menu">
                                                <li  className="dropdown-item" ><Link to="/bindState">State</Link></li>
                                                <li  className="dropdown-item" ><Link to="/bindDistrict">District</Link></li>
                                                <li  className="dropdown-item" ><Link to="/bindTaluk">Taluk</Link></li>
                                              
                                                </ul>
                                            </li> 
                                            <li className="dropdown nav-item navbar-center-align">
                                                <a className="dropdown-toggle nav-link navbar-hyber-link-color" data-toggle="dropdown" href="#">Product
                                                <span className="caret"></span></a>
                                                <ul  className="dropdown-menu">
                                                     <li  className="dropdown-item" ><Link to="/bindProduct">Product Details</Link></li>
                                                     <li  className="dropdown-item" ><Link to="/bindFullProduct">Product Full Details</Link></li>
                                                </ul>
                                            </li> 
                                            <li className="dropdown nav-item navbar-center-align">
                                                <a className="dropdown-toggle nav-link navbar-hyber-link-color" data-toggle="dropdown" href="#">Others 
                                                <span className="caret"></span></a>
                                                <ul  className="dropdown-menu">
                                                     <li  className="dropdown-item" ><Link to="/bindAdvertisement">Advertisement</Link></li>
                                                     <li  className="dropdown-item" ><Link to="/bindVehicle">Vehicle</Link></li>
                                                     <li  className="dropdown-item" ><Link to="/bindCharger">Charger</Link></li>
                                                     <li  className="dropdown-item" ><Link to="/bindService">Service</Link></li>
                                                     <li  className="dropdown-item" ><Link to="/bindInsurance">Insurance Year</Link></li>                                                   
                                                     <li  className="dropdown-item" ><Link to="/bindInsuranceCompany">Insurance Company Name</Link></li>                                                   
                                                     <li  className="dropdown-item" ><Link to="/bindVersion"> Version </Link></li>                                                   
                                                </ul>
                                            </li> 
                                            <li className="dropdown nav-item navbar-center-align">
                                                    <Link className="navbar-brand" to="/Logout">Log Out</Link>                                 
                                            </li> 
                                        </ul>
                                    </div>  
                            </nav>
                        </div>
                    </div>
            </div>
        <br />
    </div>)    
}

export default LoginHeader