export const webConfig = 'https://www.b3techstudio.com/studio/api/';

export const config ={
    headers :{
        'Content-Type' : 'application/json'
    }
}
export const formData={
    "action" : "View"
}
